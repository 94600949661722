import React, { useMemo, useEffect } from "react";
import { DataTable } from "../../../../components";
import {
	getDescendants,
	getLocationTitle,
	getSelectedSubjects,
	getSelectedYears,
	getUrlQueryParams,
	getSelectedLocations,
	getSubjectTitle,
	isCitvoiceMeasure,
	getTopLevelParent,
	getSubjectLevel, getAppSelectedLanguageId,
} from "../../../../actions";
import { setDefaultUrlQueryParamsForSubview } from "../../../../actions";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables, regionCodes } from "../../../../referenceData";
import i18n from "../../../../i18n";
import {TableFooterLegend} from "../index";

const getSubjectCategoryParent = (state, parentId) => {
	const item = state.fullListOfMeasures.filter((measure) => measure.s_id === parentId)[0];

	if (!parentId) {
		return false;
	}

	if (!item) {
		return false;
	}

	if (item.lvl == 1) {
		return item;
	}

	const parent = state.fullListOfMeasures.find((el) => el.s_id == item.ps_id);
	if (!parent || parent.s_id == parentId) {
		// should never happen, but best not to crash
		return state.fullListOfMeasures.find((e) => e.s_id == "GOVERNANCE");
	}
	return getSubjectCategoryParent(state, parent.s_id);
};

const shortenedYear = (year) => `\'${String(year).substring(2)}`;

const annualColumns = ["AAT_Range_1_Rounded", "AAT_Range_2_Rounded", "AT_Range_1", "AT_Range_2", "rank_change"];


const createTableHeader = (state, urlParams, hasDefaultYearsSelected) => {
	const tableHeader = { firstColumn: {}, records: [], annualFields: {} };
	const { loc, subview, showAAT } = urlParams;
	const selectedYearsRange1 = getSelectedYears("range1from", "range1to");
	const range1from = selectedYearsRange1[0];
	const range1to = selectedYearsRange1[selectedYearsRange1.length - 1];
	const selectedYearsRange2 = getSelectedYears("range2from", "range2to");
	const range2from = selectedYearsRange2[0];
	const range2to = selectedYearsRange2[selectedYearsRange2.length - 1];
	const selectedSubjects = getSelectedSubjects(state);

	if (selectedSubjects.length > 1) {
		tableHeader.firstColumn = 'Measure'; /* eslint-disable-line */
	} else {
		tableHeader.firstColumn = 'Location'; /* eslint-disable-line */
	}

	for (let i = Number(range1from); i <= Number(range1to); i++) {
		tableHeader.records.push(`${i}`);
	}

	if (subview === "rank" && selectedYearsRange1.length > 1) {
		tableHeader.annualFields.rank_change = [];
		tableHeader.annualFields.rank_change.push(i18n.t("Change"), `${shortenedYear(range1from)}-${shortenedYear(range1to)}`);
	}
	if (subview === "score" && JSON.parse(showAAT) < 2) {
		// order matters, for csv
		if (selectedYearsRange1.length > 1) {
			tableHeader.annualFields.AAT_Range_1_Rounded = [];
		}
		if (selectedYearsRange2.length > 1) {
			tableHeader.annualFields.AAT_Range_2_Rounded = [];
		}
		if (hasDefaultYearsSelected) {
			tableHeader.annualFields.classification = [];
		}
		if (selectedYearsRange1.length > 1) {
			tableHeader.annualFields.AT_Range_1 = [];
		}
		if (selectedYearsRange1.length > 1) {
			tableHeader.annualFields.AAT_Range_1_Rounded.push(
				i18n.t("AAT"),
				`${shortenedYear(range1from)}-${shortenedYear(range1to)}`
			);
		}
		if (selectedYearsRange2.length > 1) {
			tableHeader.annualFields.AAT_Range_2_Rounded.push(
				i18n.t("AAT"),
				`${shortenedYear(range2from)}-${shortenedYear(range2to)}`
			);
		}
		if (hasDefaultYearsSelected) {
			tableHeader.annualFields.classification.push(i18n.t("Classification"));
		}
		if (selectedYearsRange1.length > 1) {
			tableHeader.annualFields.AT_Range_1.push(i18n.t(`Change`), `${shortenedYear(range1from)}-${shortenedYear(range1to)}`);
		}
	}

	return tableHeader;
};

const supplementSelectedItemsBytFullContext = (state, selectedLocations, selectedSubjects) => {
	if (selectedSubjects.length === 1) {
		const listOfRemainingCountries = state.fullListOfCountries.filter(
			(country) => !selectedLocations.includes(country.iso)
		);

		return {
			listOfAllLocations: [...listOfRemainingCountries.map((country) => country.iso), ...selectedLocations],
			listOfAllSubjects: selectedSubjects,
		};
	} else {
		let subjectsSuplementedByFullContext = [];

		selectedSubjects.forEach((subject) => {

			// const subjectLevel = getSubjectLevel(state, subject);
			// if (subjectLevel === 1 && !subjectsSuplementedByFullContext.includes("GOVERNANCE")) {
			// 	subjectsSuplementedByFullContext.push("GOVERNANCE");
			// }
			// if (subjectLevel === 1 && !subjectsSuplementedByFullContext.includes(subject)) {
			// 	subjectsSuplementedByFullContext.push(subject);
			// }
			// if (subjectLevel !== 1 && subjectLevel !== 0) {
			// 	const categoryParent = getSubjectCategoryParent(state, subject);
			// 	if (!subjectsSuplementedByFullContext.includes(categoryParent.s_id)) {
			// 		subjectsSuplementedByFullContext.push(categoryParent.s_id);
			// 	}
			// }
		});

		subjectsSuplementedByFullContext.push("GOVERNANCE");
		return {
			listOfAllLocations: selectedLocations,
			listOfAllSubjects: subjectsSuplementedByFullContext,
		};
	}
};

export default ({ urlParams, isTakingScreenshot }) => {
	const [state, dispatch] = useAppContext();

	if (
		typeof urlParams.range1from === "undefined" ||
		typeof urlParams.range1to === "undefined" ||
		typeof urlParams.range2from === "undefined" ||
		typeof urlParams.range2to === "undefined"
	) {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const selectedYearsRange1 = getSelectedYears("range1from", "range1to");
	const selectedYearsRange2 = getSelectedYears("range2from", "range2to");
	const selectedSubjects = getSelectedSubjects(state);
	urlParams = getUrlQueryParams();
	const selectedLocations = getSelectedLocations(state);
	const measuresView = useMemo(() => selectedLocations.length === 1, [selectedLocations]);
	const dataSubset = {};
	dataSubset.values = [];
	const tableTitle = useMemo(() => {
		let title = i18n.t("{{subject}} for {{location}}", {
			subject: getSubjectTitle(state),
			location: getLocationTitle(state)
		})
		if (JSON.parse(urlParams.showAAT) === 2) {
			title += i18n.t(" - Raw Data");
		}
		return title;
	}, [selectedLocations]);

	let isValidUrlParam = function (value) {
		return value !== undefined && value !== null && value !== 'null' && value !== '0';
	};

	const filters = {
		showAAT: JSON.parse(urlParams?.showAAT || null),
		showHighest: JSON.parse(urlParams?.showHighest || true),
		showLowest: JSON.parse(urlParams?.showLowest || true),
		showTrimmed: JSON.parse(urlParams?.showTrimmed || true),
		showEstimated: JSON.parse(urlParams?.showEstimated || true),
		showTrimmedEstimated: JSON.parse(urlParams?.showTrimmedEstimated || true),
		showHighlights: JSON.parse(urlParams?.showHighlights || true),
		sortBy: isValidUrlParam(urlParams?.sortBy) ? urlParams.sortBy.toString() : null,
		sortDir: isValidUrlParam(urlParams?.sortDir) ? urlParams.sortDir : null,
		subview: urlParams?.subview || "score",
		visibleRecordColumns: getSelectedYears("range1from", "range1to").map((year) => year.toString()),
		collapseAll: JSON.parse(urlParams?.collapseAll || false),
		showFullContext: JSON.parse(urlParams?.showFullContext || false),
		...JSON.parse(urlParams?.filters || null),
	};

	const hasDefaultYearsSelected =
		selectedYearsRange1[0] === defaultVariables.default_year_array[0] &&
		selectedYearsRange1[selectedYearsRange1.length - 1] ===
			defaultVariables.default_year_array[defaultVariables.default_year_array.length - 1] &&
		selectedYearsRange2[0] === defaultVariables.default_secondary_year_array[0] &&
		selectedYearsRange2[selectedYearsRange2.length - 1] ===
			defaultVariables.default_secondary_year_array[defaultVariables.default_secondary_year_array.length - 1];

	const shortcutAll = false;

	useEffect(() => {

		const worker = new Worker("./dataTableWebWorker.js", { type: "module" });

		let selectedItemsSuplementedByFullContext;

		if (JSON.parse(urlParams.showFullContext || false)) {
			selectedItemsSuplementedByFullContext = supplementSelectedItemsBytFullContext(
				state,
				selectedLocations,
				selectedSubjects
			);
		} else {
			selectedItemsSuplementedByFullContext = {
				listOfAllLocations: selectedLocations,
				listOfAllSubjects: selectedSubjects,
			};
		}

		worker.postMessage({
			selectedLocations: selectedItemsSuplementedByFullContext.listOfAllLocations,
			selectedSubjects: selectedItemsSuplementedByFullContext.listOfAllSubjects,
			selectedYears: selectedYearsRange1,
			selectedYears2: selectedYearsRange2,
			showAll: shortcutAll,
			contextSubjects: selectedSubjects,
			urlParams,
			state,
			hasDefaultYearsSelected,
			defaultVariables,
			type: "createTable",
			activeLanguage: i18n.language,
		});

		worker.onerror = (err) => err;
		worker.onmessage = (e) => {
			if (e.data === "loading") {
				dispatch({
					type: "dataTable",
					payload: [],
				});
			} else {
				dispatch({
					type: "dataTable",
					payload: e.data,
				});
				worker.terminate();
			}
		};
	}, [state.setUrlQueryParam, i18n.language]);

	const tableRows = state?.dataTable || [];
	const tableHeader = createTableHeader(state, urlParams, hasDefaultYearsSelected);
	useEffect(() => {
		dispatch({
			type: 'tableHeader',
			payload: tableHeader,
		})
	}, [state.setUrlQueryParam, i18n.language]);
	
	const measureTreeOrder = useMemo(() => state.measureTreeOrder, [state.measureTreeOrder]);

	const onInfoIconClick = (subjectName) => {
		const description = state.descriptors.find(
			(subject) => subject.uid === `md${subjectName}` && subject.lang === getAppSelectedLanguageId()
		);
		let source = '';
		let pub = '';
		let years = '';
		let range = '';
		let coding = '';
		if (filters.subview === "score" && filters.showAAT === 2) {
			source = state.descriptors.find(
				(subject) => subject.uid === `ms${subjectName}` && subject.lang === getAppSelectedLanguageId()
			);
			pub = state.descriptors.find(
				(subject) => subject.uid === `mp${subjectName}` && subject.lang === getAppSelectedLanguageId()
			);
			years = state.descriptors.find(
				(subject) => subject.uid === `my${subjectName}` && subject.lang === getAppSelectedLanguageId()
			);
			range = state.descriptors.find(
				(subject) => subject.uid === `mr${subjectName}` && subject.lang === getAppSelectedLanguageId()
			);
			coding = state.descriptors.find(
				(subject) => subject.uid === `mc${subjectName}` && subject.lang === getAppSelectedLanguageId()
			);
		}
		return {
			"description": description.wd,
			"source": source ? source.wd : "",
			"pub": pub ? pub.wd : "",
			"years": years ? years.wd : "",
			"range": range ? range.wd : "",
			"coding": coding ? coding.wd : "",
		};
	};

	// this just puts it into appContext so that share modals etc can find these to
	// build complete URLs
	const onFilterChange = (_filterState) => {
		let filterState = {
			sortBy: _filterState.columnId,
			sortDir: _filterState.direction,
		};

		dispatch({
			type: "tableFilters",
			payload: filterState,
		});
	};

	return (
		<>
			<DataTable
				measuresView={measuresView}
				tableRows={tableRows}
				tableHeader={tableHeader}
				tableTitle={tableTitle}
				filters={filters}
				onInfoIconClick={onInfoIconClick}
				measureTreeOrder={measureTreeOrder}
				onFilterChange={onFilterChange}
				isTakingScreenshot={isTakingScreenshot}
			/>
			{ tableRows.length > 0 && <TableFooterLegend dispatch={dispatch} filters={filters} /> }
		</>
	);
};
